<template>
  <v-content
    id="pages"
    class="d-flex justify-center align-center onboarding-bg"
  >
    <v-responsive
      :style="styles"
      class="d-flex align-center"
    >
      <router-view />
    </v-responsive>
  </v-content>
</template>

<script>
  export default {
    name: 'PagesCoreView',

    data: () => ({
      srcs: {
        '/pages/login': 'back1.jpg',
        '/pages/onboard': 'back2.jpg',
      },
    }),

    computed: {
      src () {
        const path = this.srcs[this.$route.path] || 'back3.jpg'
        return require(`@/components/common/assets/backgrounds/${path}`)
      },
      styles () {
        // Making he top & bottom padding 0 to remove unnecessary scroll
        // const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        // const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150

        const paddingTop = 0
        const paddingBottom = 0
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
    },
  }
</script>
